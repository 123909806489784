import { ReactComponent as barChart } from '../../assets/svg/bar-chart.svg'
import { ReactComponent as layers } from '../../assets/svg/layers.svg'
import { ReactComponent as pieChart } from '../../assets/svg/pie-chart.svg'
import { ReactComponent as users } from '../../assets/svg/users.svg'
import { UserRole } from '../../types/user/user-role'
export const navList = [
  // {
  //   icon: barChart,
  //   path: '/dashboard',
  //   label: 'Dashboard',
  // },
  // {
  //   icon: layers,
  //   path: '/projects',
  //   label: 'Projects',
  // },
  // {
  //   icon: pieChart,
  //   label: 'Recruiting',
  //   children: [
  //     {
  //       icon: users,
  //       path: '/nested',
  //       label: 'Nested',
  //     },
  //   ],
  // },
  {
    icon: users,
    path: '/employee',
    label: 'Employees',
    roles: [
      UserRole.Sales,
      UserRole.Admin,
      UserRole.Superadmin,
      UserRole.Manager,
      UserRole.Engineer,
      UserRole.Designer,
      UserRole.HR,
    ],
  },
  {
    icon: users,
    path: '/sales',
    label: 'Sales',
    roles: [UserRole.Superadmin, UserRole.Admin, UserRole.Sales],
  },
  {
    icon: layers,
    path: '/medical',
    label: 'Medicine',
    roles: [UserRole.Superadmin, UserRole.Admin, UserRole.Doctor],
  },
]
