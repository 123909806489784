import React, { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { getProgress } from '../../store/action/ai'
import { jobProgressSelector } from '../../store/selector/ai.selector'
import { Typography } from '@mui/material'
import { BTooltip } from './Tooltip/BTooltip'

export const ProgressList: FC = () => {
  const progressMap = useAppSelector(jobProgressSelector)

  return (
    <div>
      {Object.entries(progressMap).map(([key, value]) => (
        <div key={key} style={{ marginBottom: '20px' }}>
          <Typography variant="body2" color="text.primary">
            {key} - {value.progress}
          </Typography>
        </div>
      ))}
    </div>
  )
}

export const ProgressTracker: FC = () => {
  const dispatch = useAppDispatch()
  const progressMap = useAppSelector(jobProgressSelector)
  const existActiveJob = Object.values(progressMap).some(
    ({ progress }) => progress < 100
  )

  useEffect(() => {
    dispatch(getProgress())

    const interval = setInterval(() => {
      dispatch(getProgress())
    }, 5000)

    return () => clearInterval(interval)
  }, [dispatch])

  return (
    <BTooltip title={<ProgressList />}>
      <Typography
        sx={{ height: 'fit-content' }}
        variant="body2"
        color={existActiveJob ? 'text.success_600' : 'text.primary'}
      >
        {existActiveJob ? 'Active job' : 'No active job'}
      </Typography>
    </BTooltip>
  )
}
