import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { JobStatusMap } from '../../types/ai'
import { getProgress } from '../action/ai'

interface Slice {
  progressMap: JobStatusMap
}

const initialState: Slice = {
  progressMap: {},
}

export const aiSlice = createSlice({
  name: 'ai',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getProgress.fulfilled,
      (state, { payload }: PayloadAction<JobStatusMap>) => {
        state.progressMap = payload
      }
    )
  },
})

export default aiSlice.reducer
