import { createAsyncThunk } from '@reduxjs/toolkit'
import { JobStatusMap } from '../../types/ai'
import { AiService } from '../../service/ai.service'

export const getProgress = createAsyncThunk<JobStatusMap, undefined>(
  'ai/getProgress',
  async () => {
    return await AiService.getProgress()
  }
)
