import { Grid } from '@mui/material'
import { HeadingRow } from '../common/HeadingRow/HeadingRow'
import { ProgressTracker } from '../ui/ProgressTracker'
import { BButton } from '../ui/Button/BButton'
import { TableSearch } from '../common/TableSearch/TableSearch'
import { ReactComponent as PlusIcon } from '../.././assets/svg/plus.svg'
import React, { FC } from 'react'

interface SalesAnalyzerHeaderProps {
  onAddClick: () => void
  searchQuery: string
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const SalesAnalyzerHeader: FC<SalesAnalyzerHeaderProps> = ({
  onAddClick,
  searchQuery,
  onSearchChange,
}) => {
  return (
    <Grid
      container
      sx={{ paddingBottom: 2.3, gap: 2.3, flexDirection: 'column' }}
    >
      <HeadingRow
        title="Sales Analyzer"
        subTitle="Get a detailed analysis of your calls and improve them one by one!"
      >
        <ProgressTracker />
        <BButton
          sx={{ height: 40 }}
          size="small"
          color="primary"
          startIcon={<PlusIcon />}
          onClick={onAddClick}
        >
          Add call
        </BButton>
      </HeadingRow>

      <TableSearch
        placeholder="Search by title"
        value={searchQuery}
        onChange={onSearchChange}
      />
    </Grid>
  )
}
