import React, { FC, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { DialogTree } from '../../ui/Dialog/DialogTree/DialogTree'
import { speechToTextOptionSelector } from '../../../store/selector/sales-call'
import {
  addTranscription,
  getSpeechToTextOptions,
  updateSalesCall,
} from '../../../store/action/sales-call'
import {
  EditSalesCall,
  NewSalesCallType,
  salesCallInitialValues,
  salesCallModalSteps,
} from './sales-call.modal.config'
import { ModalFooter } from '../../employee/Modal/ModalFooter'
import { FormikProps } from 'formik/dist/types'
import { parseISO } from 'date-fns'

type AddSalesCallModalProps = {
  onClose: () => void
  editCall: NewSalesCallType
  setEditCall: (newCall: NewSalesCallType) => void
}

export const EditSalesCallModalContent: FC<AddSalesCallModalProps> = ({
  editCall,
  onClose,
  setEditCall,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const formRef = useRef<FormikProps<Partial<NewSalesCallType>>>(null)
  const dispatch = useAppDispatch()
  const speechToTextOptions = useAppSelector(speechToTextOptionSelector)

  useEffect(() => {
    if (speechToTextOptions.length > 1) return
    dispatch(getSpeechToTextOptions())
  }, [dispatch, speechToTextOptions])

  const handleCreateSalesCall = async (newData: Partial<EditSalesCall>) => {
    setActiveIndex((prev) => prev + 1)
    setEditCall({ ...editCall, ...newData })
  }

  const handleAddTranscription = async (newData: Partial<EditSalesCall>) => {
    if (!newData.id || !newData.audio_link) {
      console.error('Sales call link or id not found')
      return
    }

    const transcriptionData = {
      speakerNumber: newData.count_of_speakers as string,
      speechToTextSource: newData.text_source as string,
      audioLink: newData.audio_link as string,
    }

    dispatch(
      addTranscription({
        ...transcriptionData,
        id: newData?.id,
      })
    )
    const payload = {
      ...editCall,
      call_date: parseISO(editCall.call_date ?? ''),
    }
    dispatch(updateSalesCall({ id: newData?.id, payload }))

    onClose()
    setEditCall(salesCallInitialValues)
  }

  const handleNextStep = async (newData: Partial<NewSalesCallType>) => {
    if (activeIndex === 0) {
      await handleCreateSalesCall(newData)
    } else {
      await handleAddTranscription(newData)
    }
  }

  const triggerSubmit = async () => {
    if (formRef.current) {
      await formRef.current.submitForm()
    }
  }

  const { component: StepComponent } = salesCallModalSteps[activeIndex]
  return (
    <>
      <DialogTree
        steps={salesCallModalSteps.map((step) => step.title)}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />

      <StepComponent
        formRef={formRef}
        onSubmit={handleNextStep}
        initialValues={editCall}
        onClose={onClose}
      />
      <ModalFooter
        onClose={onClose}
        onSubmit={triggerSubmit}
        submitLabel={activeIndex === 0 ? 'Next' : 'Add Call'}
      />
    </>
  )
}
