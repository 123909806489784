import { Grid } from '@mui/material'
import React, { FC } from 'react'
import { ReactComponent as CheckedBg } from '../../../../assets/svg/checked_bg.svg'
import { format, isValid, parseISO } from 'date-fns'
import * as Yup from 'yup'
import { FormDialog } from '../../../ui/Dialog/FormDialog'
import { FormDateInput } from '../../../ui/Form/DateInput/FormDateInput'

export type ConfirmPaymentValues = {
  amount: number
  paidDate: string
  periodIdentifier: string
}

type ConfirmPaymentModalProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (updatedValues: ConfirmPaymentValues) => void
  periodIdentifier: string
  salaryDue: number
  bonusAmount: number
}

const validationSchema = Yup.object().shape({
  paidDate: Yup.string()
    .required()
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Please enter correct date'),
})

export const ConfirmPaymentModal: FC<ConfirmPaymentModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  periodIdentifier,
  salaryDue,
  bonusAmount,
}) => {
  const paymentDate = format(new Date(), 'yyyy-MM-dd')

  const amount = salaryDue + bonusAmount
  const initialValue = {
    paidDate: paymentDate,
  }

  const paymentPeriod = isValid(parseISO(periodIdentifier))
    ? format(periodIdentifier, 'MMMM yyyy')
    : ''
  const subTitle = `${paymentPeriod} | Total amount: ${amount}. This action cannot be undone.`

  const handleSubmit = ({ paidDate }: { paidDate: string }) => {
    onSubmit({
      paidDate,
      amount,
      periodIdentifier,
    })
  }
  return (
    <FormDialog
      title={'Confirm payment'}
      subTitle={subTitle}
      open={isOpen}
      icon={CheckedBg}
      onClose={onClose}
      pb={'0'}
      initialValue={initialValue}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
    >
      <Grid container flexDirection={'column'} gap={2} p={3} pt={4}>
        <FormDateInput name="paidDate" label="Pay date*" />
      </Grid>
    </FormDialog>
  )
}
