export enum UserRole {
  Superadmin = 'superadmin',
  Engineer = 'engineer',
  Manager = 'manager',
  Admin = 'admin',
  Designer = 'designer',
  Sales = 'sales',
  Doctor = 'doctor',
  HR = 'hr',
}
