import { AxiosResponse } from 'axios'
import { JobStatusMap } from '../types/ai'
import apiClient from '../utils/api-client'

export class AiService {
  static async getProgress() {
    const { data } =
      await apiClient.get<AxiosResponse<JobStatusMap>>('/ai/progress')
    return data
  }
}
