import { SalesCallFormValues } from './modal/sales-call.modal.config'
import React, { FC } from 'react'
import { BDialog } from '../ui/Dialog/bDialog'
import { AddSalesCallModalContent } from './modal/AddSalesCallModalContent'
import { EditSalesCallModalContent } from './modal/EditSalesCallModalContent'
import { SimpleDialog } from '../ui/Dialog/SimpleDialog'

interface SalesCallModalsProps {
  isAddModalOpen: boolean
  isEditModalOpen: boolean
  isDeleteModalOpen: boolean
  formValues: SalesCallFormValues
  setFormValues: (values: SalesCallFormValues) => void
  onAddModalClose: () => void
  onEditModalClose: () => void
  onDeleteModalClose: () => void
  onDeleteConfirm: () => void
}

export const SalesCallModals: FC<SalesCallModalsProps> = ({
  isAddModalOpen,
  isEditModalOpen,
  isDeleteModalOpen,
  formValues,
  setFormValues,
  onAddModalClose,
  onEditModalClose,
  onDeleteModalClose,
  onDeleteConfirm,
}) => (
  <>
    <BDialog
      title="Add new sales call"
      subTitle="Add your sales call in less than 5 minutes."
      open={isAddModalOpen}
      onClose={onAddModalClose}
    >
      <AddSalesCallModalContent
        onClose={onAddModalClose}
        newCall={formValues}
        setNewCall={setFormValues}
      />
    </BDialog>

    <BDialog
      title="Edit sales call"
      subTitle="Edit your sales call in less than 5 minutes."
      open={isEditModalOpen}
      onClose={onEditModalClose}
    >
      <EditSalesCallModalContent
        onClose={onEditModalClose}
        editCall={formValues}
        setEditCall={setFormValues}
      />
    </BDialog>

    <SimpleDialog
      width="400px"
      title="Confirm Deletion"
      subTitle="Are you sure you want to delete this data? This action cannot be undone."
      onClose={onDeleteModalClose}
      onSubmit={onDeleteConfirm}
      open={isDeleteModalOpen}
      cancelLabel="Cancel"
      submitLabel="Delete"
    />
  </>
)
