import { useState, useEffect } from 'react'
import { GridSortModel } from '@mui/x-data-grid'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '../store'
import {
  saleCallListSelector,
  salesCallCountSelector,
} from '../../store/selector/sales-call'
import { getAllSalesCall } from '../../store/action/sales-call'

export const useSalesCallTable = (
  isModalOpen: boolean,
  isDeleteModalOpen: boolean,
  isEditModalOpen: boolean
) => {
  const dispatch = useAppDispatch()
  const [searchQuery, setSearchQuery] = useState('')
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 8,
    page: 0,
  })
  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const list = useAppSelector(saleCallListSelector)
  const count = useAppSelector(salesCallCountSelector)

  useEffect(() => {
    const fetchSales = () => {
      dispatch(getAllSalesCall({ paginationModel, searchQuery, sortModel }))
    }

    if (isModalOpen || isDeleteModalOpen || isEditModalOpen) return

    if (searchQuery) {
      const debouncedFetch = _.debounce(fetchSales, 800)
      debouncedFetch()
      return () => debouncedFetch.cancel()
    }

    fetchSales()
  }, [
    paginationModel,
    dispatch,
    searchQuery,
    sortModel,
    isModalOpen,
    isDeleteModalOpen,
    isEditModalOpen,
  ])

  return {
    list,
    count,
    searchQuery,
    setSearchQuery,
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
  }
}
