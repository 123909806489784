import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer, PersistConfig } from 'redux-persist'
import userReducer from './slice/user.slice'
import projectReducer from './slice/project.slice'
import compensationReducer from './slice/compensation.slice'
import paymentReducer from './slice/payment.slice'
import fileReducer from './slice/file.slice'
import salesCallReducer from './slice/sales-call.slice'
import medicalReducer from './slice/medical.slice'
import aiReduser from './slice/ai.slice'
import authReducer, { AuthSlice } from './slice/auth.slice'
const persistConfig: PersistConfig<AuthSlice> = {
  key: 'root',
  storage,
}

export const store = configureStore({
  reducer: {
    users: userReducer,
    projects: projectReducer,
    compensations: compensationReducer,
    payments: paymentReducer,
    files: fileReducer,
    sales: salesCallReducer,
    medical: medicalReducer,
    ai: aiReduser,
    auth: persistReducer<AuthSlice>(persistConfig, authReducer),
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/REGISTER',
          'persist/FLUSH',
          'persist/PAUSE',
          'persist/PURGE',
        ],
      },
    }),
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
