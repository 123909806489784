import React, { FC, useEffect, useState } from 'react'
import { Wrap } from '../layout/root/Wrap'
import { Grid } from '@mui/material'
import { BButton } from '../components/ui/Button/BButton'
import { ReactComponent as PlusIcon } from '../assets/svg/plus.svg'
import { TableSearch } from '../components/common/TableSearch/TableSearch'
import { HeadingRow } from '../components/common/HeadingRow/HeadingRow'
import { InnerWrap } from '../layout/root/InnerWrap'
import { BTable } from '../components/ui/Table/bTable/bTable'
import { GridSortModel } from '@mui/x-data-grid'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '../hooks/store'
import { BDialog } from '../components/ui/Dialog/bDialog'
import modalBackground from '../assets/employee_modal_bg.png'
import { SimpleDialog } from '../components/ui/Dialog/SimpleDialog'
import { SalesCall } from '../types/sales/sales-call'
import { AdminMedicalColumns } from '../components/medical/columns/admin.columns'
import { medicalInitialValues } from '../components/medical/modal/medical.modal.config'
import { AddMedicalModalContent } from '../components/medical/modal/AddMedicalModalContent'
import { deleteMedical, getAllMedical } from '../store/action/medical'
import {
  medicalCountSelector,
  medicalListSelector,
} from '../store/selector/medical'
import { ProgressTracker } from '../components/ui/ProgressTracker'

export const MedicalAnalyzerPage: FC = () => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const [isOpenUnsaved, setIsOpenUnsaved] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [editEntry, setEditEntry] = useState<SalesCall | null>(null)
  const [newCall, setNewCall] =
    useState<typeof medicalInitialValues>(medicalInitialValues)
  const [searchQuery, setSearchQuery] = useState('')
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 8,
    page: 0,
  })
  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const list = useAppSelector(medicalListSelector)
  const count = useAppSelector(medicalCountSelector)

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  useEffect(() => {
    const fetchSales = () => {
      dispatch(
        getAllMedical({
          paginationModel,
          searchQuery,
          sortModel,
        })
      )
    }

    if (open || isOpenDelete) return

    if (searchQuery) {
      const debouncedFetch = _.debounce(fetchSales, 800)
      debouncedFetch()
      return () => debouncedFetch.cancel()
    }

    fetchSales()
  }, [paginationModel, dispatch, searchQuery, sortModel, open, isOpenDelete])

  const handleClose = (
    event?: object | React.SyntheticEvent,
    reason?: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return setIsOpenUnsaved(true)
    }
    setOpen(false)
    setNewCall(medicalInitialValues)
  }

  const handleDelete = async () => {
    const id = editEntry?.id
    if (!id) {
      return console.error('Need id')
    }
    await dispatch(deleteMedical(id))
    setIsOpenDelete(false)
  }
  const handleOpenDeleteModal = (payload: SalesCall) => {
    toggleDeleteModal()
    setEditEntry(payload)
  }
  const toggleDeleteModal = () => setIsOpenDelete((prev) => !prev)
  return (
    <Wrap bg={'default'}>
      <InnerWrap>
        <Grid
          container
          sx={{
            paddingBottom: 2.3,
            gap: 2.3,
            flexDirection: 'column',
          }}
        >
          <HeadingRow
            title={'Medical Analyzer'}
            subTitle={
              'Get a detailed analysis of your calls and improve them one by one!'
            }
          >
            <ProgressTracker />
            <BButton
              sx={{ height: 40 }}
              size={'small'}
              color="primary"
              startIcon={<PlusIcon />}
              onClick={() => setOpen(true)}
            >
              Add item
            </BButton>
          </HeadingRow>

          <TableSearch
            placeholder={'Search by title'}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Grid>
        <BTable
          columns={AdminMedicalColumns(handleOpenDeleteModal)}
          rows={list}
          rowCount={count}
          paginationModel={paginationModel}
          paginationMode="server"
          sortingMode="server"
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
        />

        <BDialog
          title={'Add new dialog'}
          subTitle={'Add your dialog in less than 5 minutes.'}
          open={open}
          onClose={handleClose}
          bgImage={modalBackground}
        >
          <AddMedicalModalContent
            onClose={handleClose}
            newItem={newCall}
            setNewItem={setNewCall}
          />
        </BDialog>
        <SimpleDialog
          width={'400px'}
          title={'Confirm Deletion'}
          subTitle={
            'Are you sure you want to delete this data? This action cannot be undone.'
          }
          onClose={() => setIsOpenDelete(false)}
          onSubmit={handleDelete}
          open={isOpenDelete}
          cancelLabel={'Cancel'}
          submitLabel={'Delete'}
        />
      </InnerWrap>
    </Wrap>
  )
}
