import React, { useState } from 'react'
import { Wrap } from '../layout/root/Wrap'
import { InnerWrap } from '../layout/root/InnerWrap'
import { BTable } from '../components/ui/Table/bTable/bTable'
import { AdminSalesCallColumns } from '../components/sales/columns/admin.columns'
import { useAppDispatch } from '../hooks/store'
import { deleteSalesCall } from '../store/action/sales-call'
import {
  SalesCallFormValues,
  salesCallInitialValues,
} from '../components/sales/modal/sales-call.modal.config'
import { SalesCall } from '../types/sales/sales-call'
import { format } from 'date-fns'
import { useSalesCallTable } from '../hooks/sales-details/useSalesCallTable'
import { SalesAnalyzerHeader } from '../components/sales/SalesAnalyzerHeader'
import { SalesCallModals } from '../components/sales/SalesCallModals'

export const SalesAnalyzerPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [editEntry, setEditEntry] = useState<SalesCall | null>(null)
  const [formValues, setFormValues] = useState<SalesCallFormValues>(
    salesCallInitialValues
  )

  const {
    list,
    count,
    searchQuery,
    setSearchQuery,
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
  } = useSalesCallTable(isAddModalOpen, isDeleteModalOpen, isEditModalOpen)

  const handleDelete = async () => {
    if (!editEntry?.id) return console.error('Need id')
    await dispatch(deleteSalesCall(editEntry.id))
    setIsDeleteModalOpen(false)
  }

  const handleOpenDeleteModal = (payload: SalesCall) => {
    setIsDeleteModalOpen(true)
    setEditEntry(payload)
  }

  const handleOpenEditModal = (payload: SalesCall) => {
    setIsEditModalOpen(true)
    setFormValues({
      ...salesCallInitialValues,
      ...payload,
      call_date: payload.call_date
        ? format(payload.call_date, 'yyyy-MM-dd')
        : '',
    })
  }

  return (
    <Wrap bg="default">
      <InnerWrap>
        <SalesAnalyzerHeader
          onAddClick={() => setIsAddModalOpen(true)}
          searchQuery={searchQuery}
          onSearchChange={(e) => setSearchQuery(e.target.value)}
        />

        <BTable
          columns={AdminSalesCallColumns(
            handleOpenDeleteModal,
            handleOpenEditModal
          )}
          rows={list}
          rowCount={count}
          paginationModel={paginationModel}
          paginationMode="server"
          sortingMode="server"
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
        />

        <SalesCallModals
          isAddModalOpen={isAddModalOpen}
          isEditModalOpen={isEditModalOpen}
          isDeleteModalOpen={isDeleteModalOpen}
          formValues={formValues}
          setFormValues={setFormValues}
          onAddModalClose={() => {
            setIsAddModalOpen(false)
            setFormValues(salesCallInitialValues)
          }}
          onEditModalClose={() => setIsEditModalOpen(false)}
          onDeleteModalClose={() => setIsDeleteModalOpen(false)}
          onDeleteConfirm={handleDelete}
        />
      </InnerWrap>
    </Wrap>
  )
}
