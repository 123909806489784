import React, { FC, useState } from 'react'
import { FormDialog } from '../../ui/Dialog/FormDialog'
import { Grid } from '@mui/material'
import { FileUploadWithLoader } from '../../ui/Form/FileUpload/FileUploadWithLoader/FileUploadWithLoader'
import { SelectInput } from '../../ui/Form/Select/SelectInput'
import {
  BASE_DOCUMENT_TYPE,
  DOCUMENT_TYPE_OPTIONS,
} from '../../../constants/document-type.options'
import { FormInput } from '../../ui/Form/FormInput'
import { DOCUMENT_ACCESS_TYPE_OPTIONS } from '../../../constants/document-acsses.options'
import { ReactComponent as UploadIcon } from '../../../assets/svg/upload.svg'
import { ReactComponent as DocumentBgIcon } from '../../../assets/svg/document_bg.svg'
import * as Yup from 'yup'
import { useAppDispatch } from '../../../hooks/store'
import { uploadUserDocument } from '../../../store/action/user'
import { UploadUserFile } from '../../../types/user/upload-user-file'
import { FileService } from '../../../service/file.service'

const acceptedFileTypes = [
  'image/png',
  'image/jpeg',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
]
const initialValues = {
  file: '',
  name: '',
  otherName: '',
  type: '',
}
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Document name is required'),
  file: Yup.string().required('File is required'),
  otherName: Yup.string().test(
    'other-name-required',
    'Required when selecting "Other"',
    function (value) {
      const { name } = this.parent
      return !name || BASE_DOCUMENT_TYPE.includes(name) || !!value
    }
  ),
  type: Yup.string().required('Type is required'),
})
type AddDocumentModalProps = {
  open: boolean
  toggleModal: () => void
  userId: string
}
export const AddDocumentModal: FC<AddDocumentModalProps> = ({
  toggleModal,
  open,
  userId,
}) => {
  const dispatch = useAppDispatch()
  const [fileKey, setFileKey] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const [uploadPercentage, setUploadPercentage] = useState(0)

  const handleUpload = async (file: File | null) => {
    if (!file) return

    setFile(file)
    const formData = new FormData()
    formData.append('file', file)

    try {
      const key = await FileService.upload(file, `user-${userId}`, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) /
              (typeof progressEvent.total === 'number'
                ? progressEvent.total
                : 1)
          )
          setUploadPercentage(percentCompleted)
        },
      })
      setFileKey(key)
    } catch (error) {
      setFile(null)
      setUploadPercentage(0)
      console.log(error)
    }
  }
  const handleSubmit = async ({
    name: primaryName,
    otherName,
    type,
  }: typeof initialValues) => {
    const name = otherName ? otherName : primaryName
    const data: UploadUserFile = {
      name,
      type,
      upload_key: fileKey,
    }
    await dispatch(uploadUserDocument({ data, userId }))
    toggleModal()
    setFile(null)
  }

  const handleDelete = async () => {
    try {
      await FileService.delete(fileKey)
      setFile(null)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <FormDialog
      title={'Add document'}
      open={open}
      icon={DocumentBgIcon}
      onClose={toggleModal}
      pb={'0'}
      handleSubmit={handleSubmit}
      initialValue={initialValues}
      validationSchema={validationSchema}
      submitLabel={'Add'}
    >
      <Grid container flexDirection={'column'} gap={2} p={3} pt={4}>
        <FileUploadWithLoader
          name={'file'}
          Icon={UploadIcon}
          file={file}
          uploadPercentage={uploadPercentage}
          onFileSelect={handleUpload}
          onDelete={handleDelete}
          acceptedFileTypes={acceptedFileTypes}
          hideAvatar={true}
          fileTypeDescription={'PNG, JPG, DOC or PDF'}
        />
        <SelectInput
          name={'name'}
          options={DOCUMENT_TYPE_OPTIONS}
          label={'Document name*'}
          placeholder={'Select'}
        >
          {(value) =>
            value &&
            !BASE_DOCUMENT_TYPE.includes(value) && (
              <FormInput name="otherName" placeholder={'Type here...'} />
            )
          }
        </SelectInput>
        <SelectInput
          placeholder={'Select'}
          name={'type'}
          options={DOCUMENT_ACCESS_TYPE_OPTIONS}
          label={'Type*'}
        />
      </Grid>
    </FormDialog>
  )
}
